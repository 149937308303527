<template>
    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-dark">Current Month Parcel Summary </h1>
                    </div><!-- /.col -->
                    <div class="col-sm-6">
                    </div><!-- /.col -->
                </div><!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->
        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-header">
                                <form v-on:keyup.enter="searchData">
                                    <div class="row">
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <label for="business">Merchant</label>
                                                <v-select name="merchant_id"
                                                    v-model="search.merchant_id"
                                                    label="text"
                                                    :reduce="item => item.id"
                                                    :options= commonObj.merchantList
                                                    :placeholder="$t('globalTrans.select')"
                                                />
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <label for="start_date">Start Date</label>
                                                <input type="date" id="start_date" v-model="search.start_date" class="form-control" readonly>                                                
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <label for="end_date">End Date</label>
                                                <input type="date" id="end_date" v-model="search.end_date" class="form-control" readonly>                                                
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group" style="margin-top:28px;">
                                                <button class="btn btn-info btn-sm mr-2" type="submit" @click.prevent="searchData"><i class="fa fa-search"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="card-body mr-20">
                                <b-overlay :show="loader">
                                    <div class="table-responsive">
                                        <div style="width:100%; overflow-x:auto;">
                                            <table class="table table-bordered table-striped table-sm">
                                                <thead class="text-center">
                                                    <tr>
                                                        <th>SL</th>
                                                        <th>Merchant</th>
                                                        <th>Total Parcel</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tfoot>
                                                    <tr>
                                                        <th>SL</th>
                                                        <th>Merchant</th>
                                                        <th>Total Parcel</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </tfoot>
                                                <tbody>
                                                    <tr v-for="(item,key) in listData" :key="key">
                                                        <td class="text-center">{{ key+1 }}</td>
                                                        <td>{{ item.merchant ? item.merchant.business : '' }}</td>
                                                        <td class="text-center">{{ item.total_order}}</td>
                                                        <td class="text-center">  
                                                            <button class="btn btn-primary btn-sm mr-1" @click="showDetails(item.merchant_id, item.merchant.business)" title="Show Order"> <i class="fa fa-eye"></i> </button>   
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="mt-3">
                                        <b-pagination
                                            v-model="pagination.currentPage"
                                            :per-page="pagination.perPage"
                                            :total-rows="pagination.totalRows"
                                            @input="searchData"
                                        />
                                    </div>
                                </b-overlay>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!-- /.container-fluid -->
        </section>
    </div>
</template>
<script>
    import config from '@/config'
    import moment from 'moment'
    export default {
        name:'ParcelSummary',
        data () {
            return { 
                loader: false,   
                search: {
                    merchant_id: '',
                    start_date: moment().startOf('month').format('YYYY-MM-DD'),
                    end_date: moment().format('YYYY-MM-DD')
                },
                pagination: {
                    currentPage: 1,
                    totalRows: 0,
                    perPage: this.$store.state.commonObj.perPage,
                    slOffset: 1
                }
            }
        },
        created () {
            this.loadData()
        },
        computed : {
            authUser () {
                return this.$store.state.authUser
            },
            stateReload () {
                return this.$store.state.stateReload
            },
            listData () {
                return this.$store.state.list
            },
            commonObj () {
                return this.$store.state.commonObj
            }
        },
        methods:{
            searchData () {
                this.loadData()
            },
            async loadData(){    
                this.loader = true 
                const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })       
                const response = await config.getData('/merchant/parcel-summary', params)
                this.loader = false
                if (response.status == 200) {
                    this.$store.dispatch('setList', response.data.data)
                    this.paginationData(response.data)
                } else {
                    this.$store.dispatch('setList', [])
                }
            },
            paginationData (data) {
                this.pagination.currentPage = data.current_page
                this.pagination.totalRows = data.total
                this.pagination.perPage = data.per_page
                this.pagination.slOffset = this.pagination.perPage * this.pagination.currentPage - this.pagination.perPage + 1
            },
            getStatus (status) {
                if (status == 1) {
                    return 'Pending'
                } else if (status == 2) {
                    return 'Approved'
                } else if (status == 3) {
                    return 'Incomplete'
                } else {
                    return 'Reject'
                }
            },
            showDetails(merchantId, merchantBusiness) {
                this.$router.push({ name: 'merchant_parcel_summary_details', query: { merchant_id: merchantId, merchant: merchantBusiness } })
            }
        }
    }
</script>
<style>
    .w-35{
        width:35px;
    }
</style>